import * as React from 'react'

const ProgramItem = ({ programName, children }) => {
    return (
        <a href={"../" + programName} target='_blank' rel='noreferrer'>
            <div className='program-item-container'>
                <p className='program-name'>{programName}</p>
                {children}
            </div>
        </a>
    )
}

export default ProgramItem