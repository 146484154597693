import * as React from 'react'
import Layout from '../components/layout'
import Video from '../components/video'
import ProgramItem from '../components/programItem'
import { StaticImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import '../css/media.css'


const MediaPage = () => {
    return (
        <Layout pageTitle="Media" subTitle="Media" description="See photos and videos of shamisen concerts in held Tokyo Meguro with performances from Makoto Nishimura and her students.">
            <Helmet>
                {/* Imports JS fancy box plugin which adds lightbox functionality to gallery pictures */}
                <script
                    src="https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0.5/dist/fancybox.umd.js"
                    integrity="sha256-B34QrPZs5i0CQ3eqywkXHKIWw8msfAVH30RWj/i+dMo="
                    crossOrigin="anonymous"
                    key="fancybox-js"
                    defer
                ></script>
                <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0.5/dist/fancybox.css"
                    integrity="sha256-WIieo0WFPkV7kcA2lQ4ZCO5gTg1Bs/SBX5YzEB4JkyM="
                    crossOrigin="anonymous"
                    key="fancybox-css"
                ></link>
            </Helmet>

            <div className='media-page sub-wrapper'>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Gallery
                    </h2>
                    <div className='section-content'>
                        <h3 className='gallery-title'>The 12th International Hougaku Concert 2020-2-15</h3>
                        <div className='gallery-container'>
                            <StaticImage
                                data-fancybox='hougaku-2020'
                                className='gallery-img'
                                src='../images/12th-international-hougaku-concert-2020-2-15/hougaku-dress-rehersal.jpg'
                                alt='Dress rehersal in kimono for 12th International Hougaku Concert.'
                            />
                            <StaticImage
                                data-fancybox='hougaku-2020'
                                className='gallery-img'
                                src='../images/12th-international-hougaku-concert-2020-2-15/hougaku-group-photo.jpg'
                                alt='Musician group photo in kimono at the 12th International Hougaku Concert.'
                            />
                            <StaticImage
                                data-fancybox='hougaku-2020'
                                className='gallery-img'
                                src='../images/12th-international-hougaku-concert-2020-2-15/hougaku-individual-interview.jpg'
                                alt='Shamisen musician is interviewed at the 12th International Hougaku Concert.'
                            />
                            <StaticImage
                                data-fancybox='hougaku-2020'
                                className='gallery-img'
                                src='../images/12th-international-hougaku-concert-2020-2-15/hougaku-performance.jpg'
                                alt='Shamisen performance at the 12th International Hougaku Concert.'
                            />
                        </div>
                    </div>
                    <div className='section-content'>
                        <h3 className='gallery-title'>The MIFA Festival 2019-1-20</h3>
                        <p>Nishimura has regularly held concerts in co-ordination with <a href="https://mifa.jp/en/">MIFA (Meguro International Friendship Association)</a>.</p>
                        <div className='gallery-container'>
                            <StaticImage
                                data-fancybox='mifa-2019'
                                className='gallery-img'
                                src='../images/MIFA-festival-2019-1-20/mifa-dress-rehersal.jpg'
                                alt='Dress rehersal for Tokyo Meguro MIFA festival shamisen concert.'
                            />
                            <StaticImage
                                data-fancybox='mifa-2019'
                                className='gallery-img'
                                src='../images/MIFA-festival-2019-1-20/mifa-group-photo.jpg'
                                alt='Group photo at Tokyo Meguro MIFA festival.'
                            />
                            <StaticImage
                                data-fancybox='mifa-2019'
                                className='gallery-img'
                                src='../images/MIFA-festival-2019-1-20/mifa-performance.jpg'
                                alt='Shamisen performance at Tokyo Meguro MIFA festival.'
                            />
                            <StaticImage
                                data-fancybox='mifa-2019'
                                className='gallery-img'
                                src='../images/MIFA-festival-2019-1-20/mifa-rehersal-day-before.jpg'
                                alt='Concert rehersal the day before the Tokyo Meguro MIFA festival.'
                            />
                        </div>
                    </div>
                    <div className='section-content'>
                        <h3 className='gallery-title'>The MIFA Festival 2018-2-11</h3>
                        <div className='gallery-container'>
                            <StaticImage
                                data-fancybox='mifa-2018'
                                className='gallery-img'
                                src='../images/MIFA-festival-2018-2-11/mifa-rehersal.jpg'
                                alt='Rehersal for the MIFA festival shamisen concert 2018.'
                            />
                            <StaticImage
                                data-fancybox='mifa-2018'
                                className='gallery-img'
                                src='../images/MIFA-festival-2018-2-11/mifa-dress-rehersal.jpg'
                                alt='Dress rehersal for Tokyo Meguro shamisen concert.'
                            />
                            <StaticImage
                                data-fancybox='mifa-2018'
                                className='gallery-img'
                                src='../images/MIFA-festival-2018-2-11/mifa-group-photo.jpg'
                                alt='Group photo of musicians at MIFA festival shamisen concert.'
                            />
                            <StaticImage
                                data-fancybox='mifa-2018'
                                className='gallery-img'
                                src='../images/MIFA-festival-2018-2-11/mifa-performance.jpg'
                                alt='Performance at MIFA festival shamisen concert 2018.'
                            />
                        </div>
                    </div>
                    <div className='section-content'>
                        <h3 className='gallery-title'>The 11th Yakumo International Hougaku Concert 2016-6-5</h3>
                        <div className='gallery-container'>
                            <StaticImage
                                data-fancybox='yakumo-2016'
                                className='gallery-img'
                                src='../images/11th-yakumo-international-hougaku-concert-2016-6-5/yakumo-dress-rehersal.jpg'
                                alt='Dress rehersal for the 11th Yakumo international hougagku concert 2016-06-05.'
                            />
                            <StaticImage
                                data-fancybox='yakumo-2016'
                                className='gallery-img'
                                src='../images/11th-yakumo-international-hougaku-concert-2016-6-5/yakumo-group-photo.jpg'
                                alt='Group photo of shamisen players for the 11th Yakumo international hougagku concert 2016-06-05.'
                            />
                            <StaticImage
                                data-fancybox='yakumo-2016'
                                className='gallery-img'
                                src='../images/11th-yakumo-international-hougaku-concert-2016-6-5/yakumo-performance.jpg'
                                alt='Shamisen performance for the 11th Yakumo international hougagku concert 2016-06-05.'
                            />
                        </div>
                    </div>
                    <div className='section-content'>
                        <h3 className='gallery-title'>BBC and NHK Interviews</h3>
                        <p>Nishimura has been interviewed by both the BBC and NHK.<br />
                        <a href="https://www.bbc.com/travel/article/20151119-the-foreigners-keeping-kabuki-alive">Link to the BBC article about her.</a></p>
                        <div className='gallery-container'>
                            <StaticImage
                                data-fancybox='bbc-nhk'
                                className='gallery-img'
                                src='../images/bbc-and-nhk/bbc-interview-1.jpg'
                                alt='Group photo of the BBC visit to Japan to inverview Makoto Nishimura.'
                            />
                            <StaticImage
                                data-fancybox='bbc-nhk'
                                className='gallery-img'
                                src='../images/bbc-and-nhk/bbc-interview-2.jpg'
                                alt='Group photo of the BBC visit to Japan to inverview Makoto Nishimura. They are holding shamisen.'
                            />
                            <StaticImage
                                data-fancybox='bbc-nhk'
                                className='gallery-img'
                                src='../images/bbc-and-nhk/bbc-interview-3.jpg'
                                alt='BBC camera man holding camera while interviewing Makoto Nishimura.'
                            />
                            <StaticImage
                                data-fancybox='bbc-nhk'
                                className='gallery-img'
                                src='../images/bbc-and-nhk/bbc-interview-4.jpg'
                                alt='BBC camera man with camera rig for the interview of Makoto Nishimura.'
                            />
                            <StaticImage
                                data-fancybox='bbc-nhk'
                                className='gallery-img'
                                src='../images/bbc-and-nhk/nhk-interview-1.jpg'
                                alt='Group photo of when NHK came to interview Makoto Nishimura.'
                            />
                        </div>
                    </div>
                </section>

                <section className='normal-section'>
                    <h2 className='section-header'>
                        Concerts
                    </h2>
                    <div className='section-content'>
                        <p>
                            You can view these concert videos and more on <a href='https://www.youtube.com/channel/UCTf3lMh6qB1j01q1MQYOwIA/featured'>Makoto's YouTube channel.</a>
                        </p>
                        <Video />
                    </div>
                </section>

                <section className='normal-section'>
                    <h2 className='section-header'>
                        Programs
                    </h2>
                    <div className='section-content'>
                        <p>Click on a program to view the pdf in a new tab.</p>
                        <div className='program-container'>
                            {/* Program .pdf files should be placed in the "static" folder at project root. Program name is name of the .pdf file */}
                            <ProgramItem programName='program_2020.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/program_2020.jpg'
                                    alt='Cover of shamisen concert program 2020'
                                />
                            </ProgramItem>
                            <ProgramItem programName='program_2016.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/program_2016.jpg'
                                    alt='Cover of shamisen concert program 2016'
                                />
                            </ProgramItem>
                            <ProgramItem programName='program_2009.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/program_2009.jpg'
                                    alt='Cover of shamisen concert program 2009'
                                />
                            </ProgramItem>
                            <ProgramItem programName='hougaku_concert_Yukokai_2008.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/hougaku_concert_Yukokai_2008.jpg'
                                    alt='Cover of shamisen concert program 2008'
                                />
                            </ProgramItem>
                            <ProgramItem programName='hougaku_concert_2008.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/hougaku_concert_2008.jpg'
                                    alt='Cover of shamisen concert program 2008'
                                />
                            </ProgramItem>
                            <ProgramItem programName='hougaku_concert_2007.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/hougaku_concert_2007.jpg'
                                    alt='Cover of shamisen concert program 2007'
                                />
                            </ProgramItem>
                            <ProgramItem programName='hougaku_concert_2005.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/hougaku_concert_2005.jpg'
                                    alt='Cover of shamisen concert program 2005'
                                />
                            </ProgramItem>
                            <ProgramItem programName='hougaku_concert_2004.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/hougaku_concert_2004.jpg'
                                    alt='Cover of shamisen concert program 2004'
                                />
                            </ProgramItem>
                            <ProgramItem programName='hougaku_concert_2003.pdf'>
                                <StaticImage
                                    className='program-cover-img'
                                    src='../images/programs/hougaku_concert_2003.jpg'
                                    alt='Cover of shamisen concert program 2003'
                                />
                            </ProgramItem>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default MediaPage