import React from 'react'

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
    // Add new videos as an array ['Video Source', 'Video Title', 'Video Description].
    const videoArr = [
        ['https://www.youtube.com/embed/t8XX6ceNcfk', '1997.8.7“Cantan” Yokohama Noh Theatre', '“Canton” Collaborative dramatic and musical piece International Japanese Traditional small concert International Japanese Traditional small concert '],

        ['https://www.youtube.com/embed/aTd8lO05EoE', '2000.3.25  Asahi Seimei hall', 'Japanese Classical Dancer, Ms. Chie Hayashi recital Joint Concert'],

        ['https://www.youtube.com/embed/rvhopi1QovA', '2001.10.20 at Yarai Noh Theatre', 'International Hougaku Concert'],

        ['https://www.youtube.com/embed/8S9zzH3VrKE', '2002.6.29 International Nagauta Concert at Yarai Noh Theatre', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/kcy3Iri8nss', '2003.10.25  International Hougaku Concert at Tessenkai Noh Theatre', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/rNYkHi_gi7A', '2004.10.23 International Hougaku concert at Honganji', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/agPhh9tCyfI', '2005.9.4  International Hougaku Concert at Umewaka Noh Theatre', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/0M0tkRJ56fw', '2007.3.4  International Hougaku Concert at Tessenkai Noh Theatre', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/xNmlqcFfmD0', '2008.6.1  International Hougaku Concert at Umewaka Noh Theatre', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/A-Y6HO33xSk', '2008.11.2  International Hougaku Concert at International Exchange Center in Odaiba', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/1ODxfr-Xcyg', '2011.2.27 International Hougaku Concert at Motoshinmeigu Shrine (Part A)', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/4ApTUvHKvOg', '2011.2.27 International Hougaku Concert at Motoshinmeigu Shrine (Part B)', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/qFZUWEkfHoo', '2015.8.23A fresh look at traditional Japanese music', 'Traditional Japanese music'],

        ['https://www.youtube.com/embed/F2w5ifqr4bo', '2016.6.5 11 International Hougaku Concert at Motoshinmeigu Shrine', 'International hougaku Concert'],

        ['https://www.youtube.com/embed/ulvRJbUTtYM', '2018.2.11 MIFA Festival at Persimmon Hall in Meguro', 'MIFA Festival'],

        ['https://www.youtube.com/embed/5vWb8BH7OC0', '2019.1.20 MIFA Festival Persimmon Hall in Meguro', 'MIFA Festival'],

        ['https://www.youtube.com/embed/LuGjkKcjJvk', '2020.2.15 12th International Hougaku Concert at Ryugenji in Meguro', 'International hougaku Concert'],
    ]

    const returnArr = videoArr.map((video, index) => (
        <div key={index} className='video-item-container'>
            <div>
                <p className='video-title'>
                    {video[1]}
                </p>
                <div className='iframe-container'>
                    <iframe
                        src={video[0]}
                        title={video[1]}
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowfullscreen'
                        frameBorder='0'
                        webkitallowfullscreen='true'
                        mozallowfullscreen='true'
                        allowFullScreen
                        loading='lazy'
                    />
                </div>
            </div>
        </div>
    ))

    return (
        <div className='media-container'>
            {returnArr}
        </div>
    )
}

export default Video

